<template>
  <el-tag :type="type" :size="size" :disable-transitions="disableAnimations" class="tag-content">
    <slot />
  </el-tag>
</template>

<script>
const enumValidator = (values) => (value) => values.indexOf(value) !== -1;

const typeValidator = enumValidator(['danger', 'info', 'success', 'warning', 'neutral']);
const sizeValidator = enumValidator(['mini', 'small', 'medium', 'large']);

export default {
  props: {
    type: { type: String, default: 'neutral', validator: typeValidator },
    size: { type: String, default: 'mini', validator: sizeValidator },
    disableAnimations: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
::v-deep .tag-content {
  text-align: center;
}
</style>
