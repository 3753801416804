<template>
  <div v-if="useDefaultWrapper" class="table-responsive" :class="{ border, rounded }">
    <TableCore :rounded="rounded" v-bind="$attrs" v-on="$listeners">
      <template v-for="(_, name) in $scopedSlots" #[name]="slotData">
        <slot :name="name" v-bind="slotData || {}" />
      </template>
    </TableCore>
  </div>
  <TableCore v-else :rounded="rounded" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, name) in $scopedSlots" #[name]="slotData">
      <slot :name="name" v-bind="slotData || {}" />
    </template>
  </TableCore>
</template>
<script>
import TableCore from './TableCore';

export default {
  components: {
    TableCore,
  },
  inheritAttrs: false,
  props: {
    /*
            rounded
            Rounds the table corners
        */
    rounded: { type: Boolean, default: false },
    /*
            border
            Adding border to the table.
        */
    border: { type: Boolean, default: false },
    useDefaultWrapper: { type: Boolean, default: true },
  },
};
</script>
