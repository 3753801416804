<template>
  <div>
    <Table :columns="skeletonColumns" :data="skeletonData" rounded border :hover="false">
      <template v-for="item in headersData" #[item.headerSlot]>
        <span
          :key="item.key"
          class="placeholder-wave placeholder bg-secondary"
          :style="{ width: item.headerWidth + 'px', height: '16px' }"
        ></span>
      </template>

      <template v-for="item in skeletonData" #[item.contentSlot]="{ rowIndex }">
        <span
          :key="item.key"
          class="placeholder-wave placeholder bg-secondary"
          :style="{ width: item.rowWidth[rowIndex] + 'px', height: '16px' }"
        ></span>
      </template>
    </Table>
  </div>
</template>

<script>
import Table from '@/modules/core/components/Table.vue';

export default {
  name: 'TableLoadingSkeleton',
  components: {
    Table,
  },
  data: function () {
    return {
      skeletonData: [
        {
          key: 1,
          contentSlot: 'cell-col1',
          rowWidth: [16, 16, 16, 16, 16],
        },
        {
          key: 2,
          contentSlot: 'cell-col2',
          rowWidth: [92, 173, 132, 173, 100],
        },
        {
          key: 3,
          contentSlot: 'cell-col3',
          rowWidth: [169, 81, 150, 81, 100],
        },
        {
          key: 4,
          contentSlot: 'cell-col4',
          rowWidth: [34, 47, 47, 47, 65],
        },
        {
          key: 5,
          contentSlot: 'cell-col5',
          rowWidth: [76, 94, 130, 94, 120],
        },
      ],
      headersData: [
        { key: 1, headerSlot: 'header-col1', headerWidth: 16 },
        { key: 2, headerSlot: 'header-col2', headerWidth: 132 },
        { key: 3, headerSlot: 'header-col3', headerWidth: 150 },
        { key: 4, headerSlot: 'header-col4', headerWidth: 47 },
        { key: 5, headerSlot: 'header-col5', headerWidth: 130 },
      ],
      skeletonColumns: [
        {
          header: '',
          key: 'col1',
          width: '20px',
          customClass: this.$i18n.t('direction') === 'rtl' ? 'ps-2' : 'pe-2',
        },
        {
          header: '',
          key: 'col2',
          width: '100px',
          customClass: 'px-0',
        },
        {
          header: '',
          key: 'col3',
          width: '100px',
        },
        {
          header: '',
          key: 'col4',
          width: '60px',
        },
        {
          header: '',
          key: 'col5',
          width: '250px',
        },
      ],
    };
  },
};
</script>
