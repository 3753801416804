<template>
  <nav class="nav nav-tabs gap-5 g-0">
    <div v-if="startSpace" :style="{ width: startSpace }" />
    <Tab
      v-for="(tab, index) in tabs"
      :key="index"
      class="position-relative"
      :text="tab.text"
      :badge-value="tab.badgeValue"
      :disabled="tab.disabled"
      :active="activeTab === index"
      :hidden="tab.hidden"
      @click.native="handleClick(index)"
    >
      <template #prefixIcon>
        <div v-if="tab.prefixIcon" class="justify-tab-icon d-flex">
          <component :is="tab.prefixIcon" height="15px" width="15px" :class="tab.prefixClass" />
        </div>
      </template>
      <template #suffixIcon>
        <div v-if="tab.suffixIcon" class="justify-tab-icon d-flex">
          <component :is="tab.suffixIcon" height="15px" width="15px" :class="tab.suffixClass" />
        </div>
      </template>
    </Tab>
    <div v-if="$slots.actions" class="tabs-actions">
      <slot name="actions" />
    </div>
  </nav>
</template>

<script>
import Tab from './Tab';

export default {
  components: { Tab },
  props: {
    tabs: { type: Array, required: true },
    activeTab: { type: Number, default: 0 },
    startSpace: { type: String, default: null },
  },
  methods: {
    handleClick(index) {
      this.$emit('click', index);
      this.$emit('update:activeTab', index);
    },
  },
};
</script>

<style scoped lang="scss">
.tabs-actions {
  margin-inline-start: auto;
}
.justify-tab-icon {
  right: 0;
  left: 0;
}
</style>
