var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._g(_vm._b({staticClass:"filter",class:{
    clicked: _vm.active && !_vm.buttonFilter,
    'has-value': _vm.filterValue,
    'button-clicked': _vm.active && _vm.buttonFilter,
    dark: _vm.isDarkTheme,
    'button-filter': _vm.buttonFilter,
  },attrs:{"dir":_vm.$direction}},'div',_vm.$attrs,false),_vm.$listeners),[(_vm.$slots.icon)?_c('div',{staticClass:"icon-wrapper"},[_vm._t("icon")],2):_vm._e(),(_vm.filterName)?_c('div',[_c('p',{staticClass:"filter-name"},[_vm._v(_vm._s(_vm.filterName)+_vm._s(_vm.filterValue ? ':' : ''))])]):_vm._e(),(_vm.filterValue && !_vm.buttonFilter)?_vm._t("filter-value",function(){return [_c('div',[(_vm.isValueWeightBold)?_c('b',[_vm._v(_vm._s(_vm.filterValue))]):_c('span',[_vm._v(_vm._s(_vm.filterValue))])])]}):_vm._e(),(_vm.filterValue && _vm.clearable)?_c('div',{staticClass:"close-icon",on:{"click":function($event){$event.stopPropagation();return _vm.$emit('on-clear-filter')}}},[_c('CloseIcon',{attrs:{"size":16}})],1):(!_vm.buttonFilter)?_c('div',{staticClass:"chevron-icon"},[_c('ChevronLgIcon')],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }