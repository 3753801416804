<template>
  <div
    class="filter"
    :dir="$direction"
    :class="{
      clicked: active && !buttonFilter,
      'has-value': filterValue,
      'button-clicked': active && buttonFilter,
      dark: isDarkTheme,
      'button-filter': buttonFilter,
    }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div v-if="$slots.icon" class="icon-wrapper">
      <slot name="icon" />
    </div>
    <div v-if="filterName">
      <p class="filter-name">{{ filterName }}{{ filterValue ? ':' : '' }}</p>
    </div>
    <slot v-if="filterValue && !buttonFilter" name="filter-value">
      <div>
        <b v-if="isValueWeightBold">{{ filterValue }}</b>
        <span v-else>{{ filterValue }}</span>
      </div>
    </slot>
    <div v-if="filterValue && clearable" class="close-icon" @click.stop="$emit('on-clear-filter')">
      <CloseIcon :size="16" />
    </div>
    <div v-else-if="!buttonFilter" class="chevron-icon">
      <ChevronLgIcon />
    </div>
  </div>
</template>
<script>
import { ChevronLgIcon, CloseIcon } from '@/assets/icons';
export default {
  components: { ChevronLgIcon, CloseIcon },
  props: {
    filterName: { type: String, default: null },
    filterValue: { type: String, default: null },
    active: { type: Boolean, required: true },
    clearable: { type: Boolean, default: false },
    buttonFilter: { type: Boolean, default: false },
    isDarkTheme: { type: Boolean, default: false },
    isValueWeightBold: { type: Boolean, default: false },
  },
  emits: ['on-clear-filter', 'click'],
  setup() {},
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.filter {
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  border: 1px solid #d9dcde;
  width: fit-content;
  height: 2rem;
  padding: 0rem 0.5rem;
  gap: 0.25rem;
  justify-content: space-between;
  font-size: 0.875rem;
  line-height: 1.25rem;
  cursor: pointer;
  color: $typography-secondary;

  &:hover {
    background-color: #f3f3f4;
    color: $typography-primary;
  }

  .close-icon {
    margin-top: -2px;
    color: $typography-secondary;
    &:hover {
      color: $typography-primary;
    }
  }

  .chevron-icon {
    margin-top: -1px;
  }

  .filter-name {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
  }
}

.filter.button-clicked.dark {
  &:hover {
    background-color: #46494f;
    color: white;
  }
}

.filter.dark:not(.button-filter) {
  background: #1d1e20;
  color: white;
  &:hover {
    background-color: #46494f;
    color: white;
  }
}

.icon-wrapper {
  height: 22px;
}

.clicked {
  background-color: #e3e4e6;
  color: $typography-primary;

  &:hover {
    background-color: #e3e4e6;
    color: $typography-primary;
  }
}

.has-value {
  color: $typography-primary;
}

.button-clicked {
  border: 1px solid #131416;
  color: #131416;
}

.button-clicked.dark {
  background: #1d1e20;
  color: white;
}
</style>
